.dropdown-menu {
  width: 200px;
  height: auto;
  position: absolute;
  top: 38px;
  list-style: none;
  text-align: start;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: white;
  li {
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        background-color: #f8ebb2;
        border-radius: 20px;
      }
    }
  }
  #external-link {
    background-color: none;
  }
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  padding: 16px;
  font-size: 1rem;
}

@media screen and (max-width: 960px) {
  .caret-down {
    display: none;
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
