@import url('https://fonts.googleapis.com/css2?family=Hubballi&display=swap');

#landing {
  min-height: 100vh;
  height: fit-content;
  background-color: #ffffff;
}

#landing-intro {
  align-items: center;
  #landing-title {
    font-size: 2.6rem;
  }
}

#landing-content {
  color: #7c7c7c;
  display: block;
  font-size: 1.2rem;
  text-align: center;
  width: 420px;
  height: 100px;
  margin: 20px 0 0 0;
  #msg-title {
    font-family: 'Encode Sans SC', sans-serif;
    font-weight: 500;
  }
  #opening {
    white-space: nowrap;
  }
  #msg-icon {
    animation: fadeIn 2.5s infinite;
  }
}

#down-icon {
  position: absolute;
  bottom: 20px;
  font-size: 150%;
  animation: bounce 2s infinite;
}

@media screen and (max-width: 960px) {
  #landing-intro {
    width: 80%;
    #landing-title {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.4rem;
      text-align: center;
    }
  }
  #down-icon {
    display: none;
  }

  #landing-content {
    font-size: 1rem;
    text-align: center;
    width: 80%;
    margin: 20px 0 0 0;
  }
}
