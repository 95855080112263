@mixin container-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#mha {
  width: 100%;
  strong {
    color: #6e9873;
  }
  ul {
    margin-bottom: 20px;
  }
}

.mha-blue-wrapper {
  background-color: #303f5c;
  gap: 200px;
  padding: 200px 0;
  color: #fff;
}
.mha-peach-wrapper {
  background-color: #fff5ec;
  gap: 200px;
  padding: 200px 0;
}

#mha-landing {
  height: 100vh;
  background-color: #ffefde;
  #mha-title {
    position: absolute;
    font-size: 70px;
    margin-bottom: 20px;
  }
  #mha-img {
    width: 100%;
    height: auto;
  }
}

.mha-container {
  height: fit-content;
  width: 80vw;
  .mha-left {
    p {
      width: 80%;
    }
    @include container-content;
    width: 50%;
  }
  .mha-right {
    width: 50%;
    img {
      width: 14rem;
      margin-right: 5px;
      margin-left: 20px;
    }
  }
}

.mha-content {
  width: 80vw;
  .mha-left-full {
    width: 100%;
    p {
      width: 100%;
    }
  }
}

#initial-img {
  width: 100%;
  margin-left: 50px;
  margin-top: 20px;
  img {
    width: 12rem;
  }
}

#product-img {
  width: 30rem;
}

#mha-content-column {
  @include container-content;
  .mha-left {
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .mha-blue-wrapper {
    gap: 60px;
    padding: 120px 0;
  }
  .mha-peach-wrapper {
    gap: 60px;
    padding: 120px 0;
  }
  #mha-landing #mha-title {
    font-size: 1.8rem;
    margin-top: 50px;
  }
  .mha-container {
    @include container-content;
    align-items: center;
    min-height: fit-content;
    width: 80%;
    .mha-left {
      @include container-content;
      p {
        width: 100%;
      }
      width: 100%;
      margin-bottom: 30px;
    }
    .mha-right {
      flex-direction: column;
      width: 100%;
      img {
        width: 10rem;
        margin: 0 0 10px 0;
      }
    }
  }

  #initial-img {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 0px;
    img {
      width: 60%;
      margin-bottom: 20px;
    }
  }

  #product-img {
    width: 20rem;
  }
}
