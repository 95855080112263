.Home {
  width: 100%;
  height: 100%;
}

.filter {
  height: 80px;
  margin: 80px 0 50px 0;
  gap: 10px;

  .pill {
    font-family: 'Encode Sans SC', sans-serif;
    font-weight: 500;
    color: #000;
    background-color: #bdbdbd;
    border: none;
    border-radius: 20px;
    padding: 6px 16px;
    @media (hover: hover) {
      &:hover {
        background-color: rgb(154, 154, 154);
        cursor: pointer;
        transition: 0.4s;
      }
    }
  }

  #selected {
    background-color: rgb(154, 154, 154);
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    @media (hover: hover) {
      &:hover {
        background-color: rgb(154, 154, 154);
      }
    }
  }
}

#projects {
  gap: 150px;
  padding: 50px 0 80px 0;
}

@media screen and (max-width: 960px) {
  .filter {
    padding-top: 60px;
  }
  #projects {
    gap: 100px;
  }
}
