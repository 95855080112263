#prj {
  p,
  h2 {
    margin: 0;
  }
}

#prj-container {
  display: grid;
  flex-direction: row;
  grid-template-columns: 3fr 2fr;
  gap: 0px;
  margin: 0 200px;
  #prj-info-container {
    #title {
      font-weight: 700;
      margin: 5px 0;
    }
    #role {
      font-size: 1.1rem;
      color: #7c7c7c;
    }
    #tech-container {
      gap: 8px;
      margin: 20px 0;
      overflow: hidden;
      #TypeScript {
        background-color: #d59fff;
        color: #9610ff;
      }
      #JavaScript {
        background-color: rgb(253, 247, 162);
        color: rgb(74, 69, 0);
      }
      #Figma {
        background-color: rgb(255, 104, 129);
        color: rgb(106, 43, 54);
      }
      div[id='React Native'],
      #React {
        background-color: rgb(116, 225, 255);
        color: rgb(22, 96, 117);
      }
      div[id='Spring Boot'] {
        background-color: rgb(143, 211, 143);
        color: rgb(46, 84, 46);
      }
      #HTML {
        background-color: rgb(255, 182, 156);
        color: rgb(97, 40, 19);
      }
      #CSS,
      #Less {
        background-color: rgba(0, 18, 221, 0.472);
        color: rgb(35, 35, 101);
      }
      #Java {
        background-color: rgba(255, 0, 0, 0.577);
        color: rgb(80, 17, 17);
      }
    }
    #description {
      width: 90%;
      text-overflow: ellipsis;
    }
  }
  #desktop-link {
    display: block;
  }
  #mobile-link {
    display: none;
  }
  #prj-img-container {
    justify-content: flex-end;
    img {
      width: 18rem;
      height: auto;
    }
  }
}

@media screen and (max-width: 960px) {
  #prj-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    #prj-info-container {
      display: flex;
      align-items: center;
      text-align: center;
      #title {
        font-size: 1.6rem;
      }
      #role {
        font-size: 1rem;
      }
      #tech-container .tag {
        padding: 5px 12px;
        p {
          font-size: 0.675rem;
        }
      }
      #description {
        width: 80%;
      }
    }
    #desktop-link {
      display: none;
    }
    #prj-img-container {
      img {
        width: 14rem;
        height: auto;
      }
    }
    #mobile-link {
      display: block;
    }
    #MHA-mobile-img {
      margin: 50px 0;
    }
    #Mastercard-mobile-img {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 370px) {
  #prj-container #prj-info-container #tech-container {
    gap: 4px;
    .tag {
      padding: 5px 6px;
    }
  }
}
