#design {
  margin: 10% 10%;
  height: 100%;
}

#design-info {
  width: 90%;
  margin: 0;
  padding: 10px 0 0 0;
}

.gallery-item {
  width: 90%;
  margin: 20px 0;
  img {
    width: 80rem;
    max-width: 100%;
    border-radius: 4px;
  }
}

.design-description {
  margin: 20px 0;
  width: 90%;
}

@media screen and (max-width: 960px) {
  #design {
    margin: 24% 0;
  }

  #design-info {
    display: flex;
    justify-content: center;
    width: 80%;
    margin-bottom: 20px;
  }

  .gallery-item {
    width: 80%;
  }

  .design-description {
    width: 80%;
  }
}
