.club {
  gap: 100px;
  width: 100%;
  height: 100%;
  margin-top: 60px; // navbar
  strong {
    color: #0047ff;
  }
}

#pink {
  color: #b63792;
}
#orange {
  color: #d88c45;
}
#green {
  color: #37b679;
}
#purple {
  color: #7774ff;
}

.club-container {
  align-items: left;
  min-height: fit-content;
  height: 100%;
  width: 80vw;
  .img-container {
    margin-bottom: 20px;
    #figTokens {
      height: 30rem;
      width: auto;
      margin-right: 10px;
      border-radius: 4px;
    }
    #JSTokens {
      height: 16rem;
      width: auto;
      border-radius: 4px;
    }
  }
  #demo {
    width: 34rem;
  }
  .prototype {
    margin-bottom: 20px;
    img {
      max-width: 100%;
      border-radius: 4px;
    }
    #styleguide {
      width: 46rem;
    }
  }
  #data {
    width: 30rem;
  }
}

.club-container:first-of-type {
  margin-top: 50px;
}

@media screen and (max-width: 960px) {
  .club-container {
    .img-container {
      display: flex;
      flex-direction: column;
      #figTokens {
        height: 20rem;
        width: 10rem;
        margin-bottom: 5px;
      }
      #JSTokens {
        height: 12rem;
        width: 20rem;
      }
    }
  }
}
