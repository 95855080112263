#about {
  width: 100%;
  height: 100%;
  margin: 10% 0;
}

.about-opener {
  display: grid;
  grid-template-columns: 3fr 4fr;
  margin: 0 10%;
  gap: 20px;
  #intro-title {
    align-self: flex-end;
    margin-bottom: 20px;
  }
  img {
    width: 400px;
  }
}

#about-description {
  margin: 50px 10%;
}

#about-content {
  text-align: left;
  margin: 0 10%;
  min-height: 100vh;
  height: fit-content;
  #about-title {
    text-align: center;
  }
}

#highlight {
  width: fit-content;
  font-weight: 500;
  background-color: #fff9dc;
  padding: 0 4px;
  border-radius: 4px;
}

.tech {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 40px 0;
  gap: 40px;
  .tech-item {
    height: 20vh;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: 3px solid transparent;
    .icon-container {
      background-color: #deebfdc1;
      color: #5982bb;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-bottom: 10px;
      h1 {
        font-size: 24px;
      }
      .icon {
        width: 40px;
        height: 40px;
      }
    }
    .label-container p {
      font-weight: 500;
    }
  }

  .clickable {
    @extend .tech-item;
    .icon-container {
      background-color: #fff9dc;
    }
    .icon {
      color: #ffe574;
    }
    @media (hover: hover) {
      &:hover {
        border: 3px solid #f8ebb2;
      }
    }
  }
}

#about-btn-container {
  justify-content: space-evenly;
  display: none;
}

@media screen and (max-width: 960px) {
  #about {
    margin: 24% 0%;
    min-height: 100vh;
    height: fit-content;
  }

  #about-description {
    margin: 30px 10%;
  }

  .about-opener {
    display: flex;
    flex-direction: column;
    #intro-title {
      h1 {
        line-height: 2rem;
      }
    }
    img {
      align-self: center;
      max-width: 300px;
      margin-bottom: 20px;
    }
  }
  #about-content {
    min-height: 100vh;
    height: fit-content;
  }

  .tech {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .tech-item {
      height: 22vh;
      box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);
    }
    .label-container {
      text-align: center;
      height: 5vh;
      width: 90%;
      text-overflow: ellipsis;
      p {
        font-size: 0.8rem;
        line-height: 1;
      }
    }
  }
}
