@import url('https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&family=Encode+Sans+SC:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// layout
.flex {
  display: flex;
}
.grid {
  display: grid;
}
.column {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
.two-column-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-justify {
  display: flex;
  justify-content: center;
}

// elements
.highlight {
  color: #afcefa;
  font-weight: 600;
}

.underline-effect-after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: #f8ebb2;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.underline-effect-hover-after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.next-btn-container {
  @extend .container;
  margin: 0 8vw;
  .link,
  a {
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    color: #000;
    padding: 3vw 0;
    @media (hover: hover) {
      &:hover {
        color: #7c7c7c;
      }
    }
  }
}

.button {
  @extend .container;
  font-size: 1rem;
  width: 180px;
  height: 50px;
  border: none;
  font-weight: 500;
  border-radius: 4px;
  color: #000;
  background-color: #f8ebb2;
  text-align: left;
  text-decoration: none;
  @media (hover: hover) {
    &:hover {
      transform: scale(0.8);
      cursor: pointer;
      transition: 0.5s;
      background-color: #e0d49f;
    }
  }
}

.link {
  font-family: 'Encode Sans SC', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  &:hover {
    color: #7c7c7c;
    cursor: pointer;
  }
}

.nested-link {
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 500;
  color: rgb(142, 191, 255);
  font-style: italic;
  display: inline-block;
  position: relative;
  margin: 0 2px;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: #f8ebb2;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  @media (hover: hover) {
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.tag {
  @extend .container;
  max-height: 20px;
  padding: 5px 14px;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  p {
    max-height: 20px;
    font-size: 0.8rem;
  }
}

// typography
h1 {
  font-family: 'Andika', sans-serif;
}
h2 {
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 500;
}
h3 {
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 500;
}
p {
  font-size: 1.05rem;
  line-height: 1.5;
  margin-bottom: 20px;
}
ul {
  font-size: 1.05rem;
  line-height: 1.5;
  margin-left: 20px;
  li {
    margin-bottom: 10px;
  }
}
br {
  height: 3px;
}
a {
  font-family: 'Encode Sans SC', sans-serif;
  text-decoration: none;
  color: #000;
}
strong {
  font-weight: 600;
}

// animation
.animation.slide {
  opacity: 0;
  transition: all 500ms linear;
  transform: translate3d(-100px, 0px, 0px);
}

.animation.slide.in-view {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

@keyframes rotate {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    transform: rotate(60deg);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-2vh);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes slidein {
  0% {
    margin-left: 0%;
  }
  50% {
    margin-left: 100px;
  }
  100% {
    margin-left: 0%;
  }
}

@media screen and (max-width: 960px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1rem;
  }
  p,
  ul {
    font-size: 0.95rem;
  }
  .two-column-table {
    display: flex;
    gap: 0px;
    flex-direction: column;
  }
}
