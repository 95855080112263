nav {
  position: fixed;
  top: 0;
  background-color: rgb(255, 255, 255);
  width: 100%;
  z-index: 100;
  transition: top 0.3s;
}

#navbar-container {
  justify-content: space-between;
  height: 60px;
  margin: 0 20px;
  #nav-profile-container {
    #name {
      font-family: 'Andika', sans-serif;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 0 10px;
      margin-left: 5px;
    }
    #icon {
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }
    @media (hover: hover) {
      &:hover {
        #name {
          background-color: #f8ebb2;
          border-radius: 10px;
          transition: 0.5s;
        }
      }
    }
  }
}

.mobile-icon {
  display: none;
}

.nav-menu-active,
.close {
  display: flex;
  flex-direction: row;
  .nav-item {
    width: auto;
    padding: 0 10px;
    list-style-type: none;
    @media (hover: hover) {
      &:hover {
        background-color: #f8ebb2;
        border-radius: 10px;
        transition: 0.25s linear;
      }
    }
    .link {
      display: inline-block;
      position: relative;
      font-size: 1rem;
    }
  }
  .experience-item {
    display: none;
  }
}

#footer {
  width: 100%;
  height: 60px;
  text-align: center;
  p {
    font-size: 0.85rem;
  }
  #footer-content {
    justify-content: space-between;
  }
}

@media screen and (max-width: 960px) {
  #navbar-container {
    height: 50px;
    #nav-profile-container #icon {
      width: 35px;
      height: 35px;
    }
  }

  .close {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 40vh;
    position: absolute;
    top: 60px;
    opacity: 1;
    display: none;
  }

  .nav-menu-active {
    display: block;
    position: absolute;
    flex-direction: column;
    width: 100%;
    margin: 0;
    min-height: 40vh;
    height: fit-content;
    top: 50px;
    left: 0;
    padding-top: 10px;
    padding-left: 20px;
    background: white;
    z-index: 2;
    box-shadow: 0px 8px 10px -10px rgba(20, 20, 20, 0.25);
    .nav-item {
      padding: 0;
      margin-top: 10px;
      &:hover {
        background-color: transparent;
      }
      #experiences-mobile {
        &:hover {
          color: black;
          cursor: auto;
        }
      }
      .link {
        font-weight: 500;
        margin: 0px;
        display: block;
        width: 90%;
        height: 100%;
      }
    }
    .experience-item {
      margin-top: 8px;
      display: block;
      line-height: 24px;
      width: 100%;
      .nav-link {
        font-weight: 300;
        margin-left: 20px;
      }
    }
  }

  .mobile-icon {
    display: block;
    position: absolute;
    top: -8px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.4rem;
    cursor: pointer;
  }

  .open {
    cursor: pointer;
    color: #f8ebb2;
  }
}
